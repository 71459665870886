import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
// 重复请求相同路径警告
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: "/",
    redirect: "/postpone/onLine",
  },
  {
    path: '/postpone',
    component: () => import('./views/admin'),
    children: [
      {
        name: 'onLine',
        path: '/postpone/onLine',
        component: () => import('./views/onLine'),

      },
      {
        name: 'orderForm',
        path: '/postpone/orderForm',
        component: () => import('./views/orderForm'),

      },
      {
        name: 'pay',
        path: '/postpone/payApply',
        component: () => import('./views/payApply'),
      },
      {
        name: 'operation',
        path: '/postpone/operation',
        component: () => import('./views/operation'),
      }
    ]
  },
  {
    path: '*',
    component: () => import('./views/404')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
export default router
