<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  data () {
    return {
      timer: null
    }
  },
  created () {
  },
  methods: {
  },
  beforeDestroy () {
  },
  watch: {
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  //text-align: center;
}

html,
body{
  margin:0px;
  padding-right:0px !important;
}
#app {
  min-height: 100%;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background-color: #F2F4F7;

}

*{
  box-sizing: border-box;
}

</style>
